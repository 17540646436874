<template>
    <vs-popup id="add-commission-to-partner" :title="title" :active="visible" @close="onClose"
              class="send-invoice-modal">
        <vs-alert color="#ff0000" :active="true" icon="error" class="mb-4 small-alert">
            Du kannst hier eine Gutschrift für einen Gutschriftberechtigtes Unternehmen erstellen. Bitte prüfe vorher
            und später unter dem Menüpunkt "Abrechnungen" ob alles korrekt erstellt wurde.
        </vs-alert>
        <div class="position-container pt-4" v-if="orderData.items && accountings.length >= 1">

            <div class="w-full flex mb-8 font-bold">
                <div class="w-5/12">
                    Rechnungsempfänger: {{ orderData.client.company_name }}
                </div>
                <div class="w-1/12">

                </div>
                <div class="w-6/12">
                    <div class="w-full flex">
                        <div class="w-6/12">
                            Wer erhält die Gutschrift:<br>
                            <mg-select v-model="selectedClientId" :options="clients" name="clients" data-vv-as="Kunden"
                                       placeholder="Optional: Partner-Unternehmen auswählen" track-by="id" label="id"
                                       :custom-label="customClientLabel" v-validate="'required'">
                                <template slot="singleLabel" slot-scope="{ option }">
                                    <strong>{{ option.company_name }}</strong>
                                </template>

                                <template slot="option" slot-scope="props">
                                        <span>
                                            {{ props.option.company_name }} <small>(Kd. Nr.: {{ props.option.customer_number }})</small></span>
                                </template>
                            </mg-select>
                            <span class="text-danger text-sm" v-if="errors.has('clients')">Dieses Feld ist ein Pflichtfeld</span>
                        </div>
                        <div class="w-6/12 pl-5">
                            Wie viel % Gutschrift? <br>
                            <vs-input v-model="selectedCommissionPercentage" @input="onChangeCommissionPercentage"></vs-input>
                            <small v-if="!selectedClientId">Bitte wähle zuerst das Partner-Unternehmen aus.</small>
                            <small v-if="selectedClientId">Bei dem Partner ist eine Provision von:
                                <u>{{ selectedClientData.b2b_partner_commission }}%</u> hinterlegt</small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-full flex">
                <div class="w-5/12">
                    <u>Rechnungs-Position</u>
                </div>
                <div class="w-1/12">
                    ----> Wird zu ---->
                </div>
                <div class="w-6/12">
                    <div class="w-full flex">
                        <u>Gutschriftsposition</u>
                    </div>
                </div>
            </div>
            <div v-for="(item,index) in orderData.items" class="position mb-2">
                <div class="w-full flex">
                    <div class="w-5/12">
                        <template>
                            <div class="name" v-html="item.product_name">
                                {{ item.product_name }}
                            </div>
                            <div class="clearfix"></div>
                            <div class="description" v-html="item.short_description"
                                 v-show="billingPromptShowArticleDescription">
                                {{ item.short_description }}
                            </div>
                            <u>Summe (netto): {{ item.amount }} x {{ item.unit_price_netto|currency }}</u>
                        </template>
                    </div>
                    <div class="w-1/12 text-center">
                        <span style="font-size: 25px"> > </span>
                    </div>
                    <div class="w-6/12">
                        <div class="w-full flex">

                            <div class="w-3/5">
                                <div class="w-full ">
                                    <mg-select :options="products"
                                               track-by="id"
                                               label="name"
                                               placeholder="Produkt auswählen"
                                               @select="onSelectProduct(index)"
                                               v-model="accountings[index].article_assignment_id"
                                    >
                                    </mg-select>
                                    <div class="w-full flex mt-2">
                                        <vs-input class="w-9/12 "
                                                  label-placeholder="Produktname"
                                                  v-model="accountings[index].product_name"
                                        />
                                        <span class="w-1/12 p-6" title="Menge">{{ accountings[index].amount }}x</span>
                                        <vs-input class="w-2/12 ml-2" disabled=""
                                                  label-placeholder="Summe Provision"
                                                  v-model="accountings[index].unit_price_netto"
                                                />


                                    </div>
                                    <label>Kurzbeschreibung:</label>
                                    <vs-textarea class="w-full mt-0 "
                                              label-placeholder="Kurzbeschreibung"
                                              v-model="accountings[index].short_description"
                                    />

                                    <i>Summe: {{ (accountings[index].unit_price_netto * accountings[index].amount)|currency}}</i>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <vs-row>
            <vs-col vs-justify="right" vs-align="right" vs-w="12" class="mt-4 text-right">
                <vs-button color="success" type="filled"
                           @click="onSubmitAccounting">Positionen abrechnen
                </vs-button>
            </vs-col>
        </vs-row>
    </vs-popup>

</template>

<script>
import staticOptions from "@/mixins/static/options";
import moment from "moment/moment";
import {mapGetters} from "vuex";
import ApiService from "@/api";
import MgSelect from "../../components/mg-select/MgSelect";
import * as qs from "qs";

export default {
    name: "addCommissionToPartner",
    components: {
        MgSelect
    },
    props: {
        active: {
            type: Boolean,
            default: false
        },
        orderData: {
            type: Object,
            default: null
        },
        title: {
            type: String,
            default: 'Gutschrift für Rechnung erstellen'
        },
        sendLabel: {
            type: String,
            default: 'Gutschriften erstellen'
        },
    },
    data() {
        return {
            accountings: [],
            billingInlineEditIndex: null,
            billingPromptAddOfferReference2ProductName: true,
            billingPromptShowArticleDescription: true,
            selectedCommissionPercentage: 0,
            selectedClientId: null,
            clients: [],
            products: []
        }
    },
    computed: {
        ...mapGetters([
            'workspace'
        ]),
        visible() {
            return this.active;
        },
        selectedClientData() {
            if (this.clients.length === 0) {
                return null;
            }
            return this.clients.find(item => item.id === parseInt(this.selectedClientId));

        },
    },
    created() {

        this.setPartnerClientId()
        this.fetchClients()
        this.fetchProducts().then(() => {
            this.createInitialAccountingData()
        });
    },
    methods: {
        setPartnerClientId() {
            if (this.orderData.client.b2b_assigned_partner_id) {
                this.selectedClientId = this.orderData.client.b2b_assigned_partner_id
            }
        },
        fetchProducts() {
            return ApiService.get('articles', {
                params: this.requestParams,
                paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
            }).then((response) => {
                if (response.data.status === 'success') {
                    this.products = response.data.result;

                }
            });
        },
        fetchClients() {
            return ApiService.get(`clients`)
                .then(response => {
                    if (response.status == 200) {
                        this.clients = response.data.result;
                    } else {
                        this.$vs.notify({
                            title: 'Fehler beim Kunden laden',
                            text: '',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Fehler beim Kunden laden',
                        text: '',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                });
        },
        onClose() {
            this.$emit('update:active', false)
        },
        onSubmitAccounting() {


            let items = [];

            for(let i = 0;i < this.accountings.length; i++) {

                let item = JSON.parse(JSON.stringify(this.accountings[i]))

                item["client_id"] = this.selectedClientId;
                item["workspace_id"] = this.workspace.id;
                item["unit_price_netto"] = "-" + item["unit_price_netto"];

                console.log(item)
                items.push(item);

            }
            if(items.length === 0) {
                this.$vs.notify({
                    title: 'Fehler',
                    text: 'Keine Abrechnungen vorhanden',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })

            }

            if(items.length >= 1) {
                this.$vs.loading()
                ApiService.post('accountings/bulk', items).then((response) => {

                    this.$vs.notify({
                        title: 'Erfolgreich',
                        text: 'Die Abrechnungspositionen wurden erfolgreich angelegt',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    });

                    this.$vs.loading.close()
                    this.onClose();

                }).catch((error) => {
                    this.$vs.notify({
                        title: 'Fehler',
                        text: 'Die Abrechnungspositionen konnten nicht erstellt werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });

                    this.$vs.loading.close()
                })


            }
        },
        unitName(value) {
            let name = staticOptions.units.find((item) => item.value == value);

            if (name === null || name === undefined) {
                return null;
            }

            return name.labelShort;
        },
        formatDate(value) {
            return moment(new Date(value)).format('DD.MM.YYYY')
        },
        customClientLabel(option) {
            return `${option.customer_number} - ${option.company_name}`;
        },
        onSelectProduct(i) {
            this.accountings[i]["product_name"] = this.accountings[i]["product_name"]
        },
        getProductById(id) {
            if (this.products.length == 0) {
                return null;
            }

            let obj = this.products.find(x => x.id === id);
            let index = this.products.indexOf(obj);
            return obj;
        },
        createInitialAccountingData() {
           // console.log(this.orderData.items)
            for (var i = 0; i < this.orderData.items.length; i++) {
                let productId = 203;
                let product = this.getProductById(productId)
                this.accountings.push({
                    "amount": this.orderData.items[i].amount,
                    "article_assignment_id": productId,
                    "product_name": product.name.replace('PLUGINNAME ', '') + this.orderData.items[i].product_name,
                    "client_id": null,
                    "is_hour_unit": 0,
                    "short_description": "Client: " + this.orderData.client.company_name,
                    "unit_price_netto": 0,
                    "volume_unit": parseInt(this.orderData.items[i].volume_unit)
                });
            }
        },
        onChangeCommissionPercentage(value){

            for (var i = 0; i < this.accountings.length; i++) {
                this.accountings[i].unit_price_netto = this.orderData.items[i].unit_price_netto * parseFloat(value) / 100;
            }
        }
    },


}
</script>

<style scoped>
.position-container .position {
    padding: 10px
}

.position-container .position:nth-child(even) {
    background-color: #e0e0e0;
}

.name {
    font-weight: bold;
}

</style>