<template>
    <div class="history">
        <div class="history-title">E-Mail Verkehr</div>
        <div class="history-items">
            <vs-alert color="#7795D1" :active="true" icon="info" class="mb-4" v-show="!data">
                Keine vesendete Mails vorhanden
            </vs-alert>
            <template v-if="data">
                <log-item v-for="mail in data" :mail="mail"></log-item>
            </template>
        </div>
    </div>
</template>

<script>
    import LogItem from "./EmailLogItem";
    import ApiService from "../../api";
    import * as qs from "qs";

    export default {
        name: "EMailLogWidget",
        components: {LogItem},
        props: {
            object_type: {
                type: String
            },
            object_id: {
                type: Number,
            }
        },
        data() {
            return {

                data: [],
            }
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            async fetchData() {
                this.$vs.loading();

                let queryParams = {
                    filter: [
                        {
                            field: 'object',
                            expression: 'exact',
                            value: "App\\Models\\Order\\Order"
                        },
                        {
                            field: 'object_id',
                            expression: 'exact',
                            value: this.object_id
                        }
                    ]
                };

                ApiService.get('mail-log', {
                    params: queryParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                })
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.data = response.data.result.result;
                    }
                })

            },
        }
    }
</script>

<style scoped>

</style>