<template>
    <vs-prompt
            @accept="acceptPayedDialog"
            title="Zahlung erfassen"
            @close="$emit('close')"
            acceptText="Speichern"
            cancelText="Abbrechen"
            :active.sync="activePayedPrompt">
        <span>Zahlung am:</span>
        <datepicker v-model="date_payed" placeholder="Datum wählen" class="mb-4"
                    format="dd.MM.yyyy"></datepicker>
    </vs-prompt>
</template>

<script>
    import ApiService from "../../api";
    import Datepicker from '@sum.cumo/vue-datepicker';
    import moment from 'moment';

    export default {
        name: "PayDatePrompt",
        components: {Datepicker},
        props: [
            'active',
            'orderId',
            'paidStatusId'
        ],
        data() {
            return {
                activePayedPrompt: false,
                date_payed: moment().format('MM-DD-YYYY'),
            }
        },
        methods: {
            activeChange(value) {
                this.activePayedPrompt = value;
            },
            acceptPayedDialog() {
                ApiService.put(`orders/${this.orderId}`, {status: this.paidStatusId, paid_on_date: moment(this.date_payed).format('Y-M-D')}).then(response => {
                    if(response.status === 200) {
                        this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'Die Rechnung wurde als bezahlt markiert.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        });
                    } else {
                        this.$vs.notify({
                            title: 'Fehler',
                            text: response.message,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }

                    this.$emit('close');
                }).catch((response) => {
                    this.$vs.notify({
                        title: 'Fehler',
                        text: response.message,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                    this.$emit('close');
                });
            },
        },
        watch: {
            active: 'activeChange'
        }
    }
</script>