<template>
    <div class="history-item">
      <div class="history-item__type"><div class="mg-label" ><span v-for="mailto in jsonParseKeys(mail.to)">{{mailto}}; </span></div></div>
        <div class="history-item__date">{{mail.created_at | date}}</div>
        <div class="history-item__description">{{mail.subject}}   <feather-icon icon="SearchIcon"  class="inline-block mr-2 btn" svgClasses="w-4 h-4" /></div>
        <div class="history-item__user">Von: {{mail.from}} </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: "EmailLogItem",
        props: {
            mail: {
                type: Object,
            }
        },
        computed: {

        },
      methods: {
       jsonParseKeys(myObjStr) {
                      return Object.keys(JSON.parse(myObjStr));
                  }
      },
        filters: {
            date: function (value) {
                return 'am ' + moment(value).format('DD.MM.YYYY HH:mm') + 'Uhr';
            }
        }
    }
</script>

<style scoped>

</style>