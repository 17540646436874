<template>
    <vs-popup title="Stornorechnung erstellen" :active="visible" @close="onClickClose" class="order-cancel-modal">
        {{order}}
    </vs-popup>
</template>

<script>
    export default {
        name: "OrderCancelModal",
        props: {
            order: {
                type: Object,
                default: function() {
                    return null;
                }
            },

            active: {
                type: Boolean,
                default: false
            },
        },

        computed: {
            visible() {
                return this.active;
            },
        },

        methods: {
            onClickClose() {
                this.$emit('update:active', false)
            },
        }
    }
</script>

<style scoped>

</style>