<template>
    <div class="order-view">
        <div class="order-view__options w-full flex">
            <div class=" w-3/4 ">
                <div class="order-view__viewer bg-white rounded shadow-md p-1 ">
                    <div id="order-pdf-viewer"></div>
                </div>
                <div class=" mt-5 p-5 bg-white rounded shadow-md" v-if="order">
                    <h3 class="mb-5 mt-4">Aufgabenzuweisung</h3>
                    <div v-for="(item,index) in order.items" class="taskAllocationItem">
                        <h5 class="pb-5">Pos {{index+1}}: {{item.product_name}}</h5>
                        <task-allocation
                                data-typ="order_item"
                                :dataId="item"
                                ref="TaskAllocationData">
                        </task-allocation>
                    </div>

                </div>

            </div>
            <div class="w-1/4 flex flex-col pl-4" v-if="order">
                <vs-alert v-if="isPaid" class="mb-2" color="info">Rechnung wurde bezahlt am: {{paidOnDate}}</vs-alert>
                <vs-button icon="send" color="success" class="mb-2" v-if="order.reffered_by === null" @click="openSendInvoiceModal">Versenden</vs-button>
                <vs-button icon="get_app" color="success" class="mb-2" target :href="documentUrl+'?download=1'">PDF Download</vs-button>

                <template v-if="!isPaid && !order.type.is_cancellation">
                    <vs-button v-if="order.reffered_by === null" icon="attach_money" color="success" type="border" class="mb-2 w-full" @click="activePayedPrompt=true">Zahlung erfassen</vs-button>

                    <pay-date-prompt v-if="order.reffered_by === null" :order-id="$route.params.id" :paid-status-id="paidStatusId" :active="activePayedPrompt" @close="onPayDatePromptClose"></pay-date-prompt>

                    <vs-button v-if="order.reffered_by === null && order.type.name != 'Gutschrift'" :to="'/invoices/duplicate/'+$route.params.id+'?type=cancellation'" icon="cancel" color="warning" type="border" class="mb-2">Stornieren</vs-button>

                    <vs-button icon="warning" color="warning" class="mb-2" @click="openSendPaymentReminderModal">Zahlungserinnerung</vs-button>
                    <vs-button icon="warning" color="danger" class="mb-2" @click="openSendPaymentReminder2Modal">Mahnungen</vs-button>

                    <vs-button :to="'/invoices/duplicate/'+$route.params.id" icon="content_copy" color="dark" type="border" class="mb-2">Duplizieren</vs-button>
                    <vs-button v-if="order.type.name === 'Gutschrift'" icon="remove_circle_outline" color="danger" class="mb-2" @click="onClickDeleteOrder">Gutschrift löschen</vs-button>
                    <vs-button :to="'/invoices/'+$route.params.id" v-if="permission_edit_open_orders"  icon="backspace" color="danger" type="border" class="ml-auto">Zurück zum Entwurf</vs-button>

                    <vs-prompt
                            @accept="onDeleteOrder"
                            title="Gutschrift löschen"
                            @close="deleteOrderPrompt=false"
                            acceptText="Löschen"
                            cancelText="Abbrechen"
                            :active.sync="deleteOrderPrompt">
                        <p>Sind Sie sicher dass die Gutschrift <u>endgültig</u> gelöscht werden soll</p>
                    </vs-prompt>
                </template>


                <vs-button v-if="order.order_type_id === 7" icon="remove_circle_outline" color="success" class="mt-2 mb-2" @click="onClickCreateCreditNote">Provision gutschreiben</vs-button>
                <vs-button v-if="order.order_type_id === 11" icon="remove_circle_outline" color="success" class="mt-2 mb-2" @click="onClickAddBankTransfer">Gutschrift zum nächsten Rechnungslauf hinzufügen</vs-button>

                <template v-if="order.type.is_cancellation">
                    <vs-button icon="remove_circle_outline" color="danger" class="mb-2" @click="onClickDeleteOrder">Stornorechnung löschen</vs-button>
                    <vs-button :to="'/invoices/'+$route.params.id" v-if="permission_edit_open_orders" icon="backspace" color="danger" type="border" class="ml-auto">Zurück zum Entwurf</vs-button>

                    <vs-prompt
                            @accept="onDeleteOrder"
                            title="Stornorechnung löschen"
                            @close="deleteOrderPrompt=false"
                            acceptText="Löschen"
                            cancelText="Abbrechen"
                            :active.sync="deleteOrderPrompt">
                        <p>Sind Sie sicher dass die Stornorechnung <u>endgültig</u> gelöscht werden soll</p>
                    </vs-prompt>
                </template>

                <send-invoice-modal
                        :send-only="true"
                        :active.sync="sendInvoiceModalActive"
                        :template="compiledMailTemplate"
                        :emails="sendInvoiceEmails"
                        :title="`${this.order.type.name} versenden`"
                        :sendLabel="`${this.order.type.name} senden`"
                        @send="sendInvoiceMail">

                </send-invoice-modal>

                <add-commission-to-partner
                    :active.sync="createCreditNote"
                    :orderData="order">
                </add-commission-to-partner>


                <div class="text-base font-bold mb-2">Interne Notiz</div>

                <vs-textarea label="Interne Notiz" v-model="order.notice" class="shadow-md bg-white mb-8"></vs-textarea>

                <div class="text-base font-bold mb-2">Zugeordnete Kategorien</div>

                <category-selector v-model="order.categories" type="order" class="mb-8 shadow-md rounded"></category-selector>

                <div class="text-base font-bold mb-2">Zugeordnete Tags</div>

                <tag-selector v-model="order.tags" type="order" class="shadow-md rounded"></tag-selector>

                <vs-button color="success" class="mt-4" @click="onSaveOrder">Speichern</vs-button>

                <history :histories="order_history" class="mt-4"></history>
                <email-log object_type="App\Models\Order\Order" :object_id="order.id" class="mt-4"></email-log>


            </div>
        </div>

        <order-cancel-modal :order="order" :active.sync="orderCancelModal"></order-cancel-modal>
    </div>
</template>

<script>
    import * as PDFObject from 'pdfobject';
    import ApiService from "../../../api";
    import Datepicker from '@sum.cumo/vue-datepicker';
    import moment from 'moment';
    import OrderCancelModal from "../../../components/order/OrderCancelModal";
    import PayDatePrompt from "../../../components/invoice/PayDatePrompt";
    import SendInvoiceModal from "../../../components/invoice/SendInvoiceModal";
    import CategorySelector from "../../../components/search/CategorySelector";
    import TagSelector from "../../../components/search/TagSelector";
    import History from "../../../components/history/History";
    import EmailLog from "../../../components/emails/EMailLogWidget";
    import TaskAllocation from "../../../components/commision/TaskAllocation";
    import * as qs from "qs";
    import {mapGetters} from "vuex";
    import AddCommissionToPartner from "@/components/invoice/addCommissionToPartner.vue";
    import PriceHelper from "@/mixins/helper/price.helper";

    export default {
        name: "ViewOrder",
        components: {
            AddCommissionToPartner,
            History, EmailLog, TaskAllocation,
            TagSelector, CategorySelector, SendInvoiceModal, PayDatePrompt, OrderCancelModal, Datepicker},
        data() {
            return {
                order: null,
                order_status: [],
                order_history: [],
                activePayedPrompt: false,
                orderCancelModal: false,
                createCreditNote: false,
                deleteOrderPrompt: false,
                sendInvoiceModalActive: false,
                compiledMailTemplate: null,
                permission_edit_open_orders: false
            }
        },
        computed: {
          ...mapGetters(['workspace']),
            paidStatusId() {
                const status = this.order_status.find(item => item.is_default_paid)
                if(!status) {
                    return null;
                }
                return status.id;
            },
            isPaid() {
                const status = this.order_status.find(item => item.id == this.order.status);

                if(status) {
                    return status.is_default_paid === 1;
                }

                return false;
            },
            isDraft() {
                const status = this.order_status.find(item => item.id == this.order.status);

                if(status) {
                    return status.is_draft === 1;
                }

                return false;
            },
            paidOnDate() {
                return moment(this.order.paid_on_date).format('D.MM.Y');
            },
            sendInvoiceEmails() {
                if (!this.order) {
                    return null;
                }

                if(!this.order.client) {
                    return null;
                }

                return this.order.client.invoice_emails;
            },

            invoiceRecipientContact() {
                if (!this.order) {
                    return null;
                }

                if (this.order.client.contacts === null) {
                    return null;
                }

                const contact = this.order.client.contacts.filter(item => item.pivot.is_invoice_recipient);

                if (contact) {
                    return contact
                }

                return null;
            },

            additionalInvoiceRecipient() {
                if (!this.order) {
                    return null;
                }

                if (this.order.client.additional_invoice_recipient) {
                    this.order.client.additional_invoice_recipient.split(';');
                }

                return null;
            },
            documentUrl() {
                if(!this.order) {
                    return  null;
                }

                return `${process.env.VUE_APP_API_URL}document/${this.workspace.id}/order/${this.order.type.invoice_type}/${this.order.file}`;
            },
            orderHistoryRequestParams() {
                return {
                    order: {
                        'field': 'created_at',
                        'direction': 'DESC'
                    }
                }
            }
        },
        mounted() {
            this.$vs.loading();

            const id = this.$route.params.id;
            const version = Math.floor((Math.random() * 1000000) + 1);

            const orderStatusPromise = ApiService.get('order_status?filter[invoice_type]=invoice');
            const orderDataPromise = ApiService.get(`orders/${id}`);

            const orderHistoryPromise = ApiService.get(`order_history/${id}`, {
                params: this.orderHistoryRequestParams,
                paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
            });
            this.checkIfUserHasPermission();

            Promise.all([orderStatusPromise, orderDataPromise, orderHistoryPromise]).then(response => {
                if (response[1].status !== 200 || response[0].status !== 200 || response[2].status !== 200) {
                    return this.$router.push('/invoices');
                }

                this.order_status = response[0].data.result;
                this.order = response[1].data.result;
                this.order_history = response[2].data.result.result;

                if(this.isDraft) {
                    return this.$router.push('/invoices/' + this.order.id);
                }

                PDFObject.embed(`${process.env.VUE_APP_API_URL}document/${this.workspace.id}/order/${this.order.type.invoice_type}/${this.order.file}?v=${version}`, '#order-pdf-viewer');

                this.$vs.loading.close()
            }).catch((response) => {

                this.$vs.loading.close();
                this.$router.push('/invoices');
            });
        },
        methods: {
            checkIfUserHasPermission() {
                return ApiService.post(`auth/functionPermission`, {permissionKey: "edit_open_orders"} )
                    .then(response => {
                        if (response.data.status == "success") {
                            this.permission_edit_open_orders = true;

                        } else {
                            this.permission_edit_open_orders = false;
                        }
                    });
            },
            openSendInvoiceModal() {
                const typeId = this.$route.params.id;
                const name = this.order.type.mail_template.name;
                const language = this.order.client.country == 1 ? 'de':'en';

                ApiService.get(`mail-templates/${name}/order/${typeId}/${language}`).then((response) => {
                    if (response.status !== 200) {
                        return;
                    }

                    this.compiledMailTemplate = response.data.result;
                    this.sendInvoiceModalActive = true;
                });

            },
            openSendPaymentReminderModal() {
                const typeId = this.$route.params.id;

                ApiService.get(`mail-templates/invoice_payment_reminder/order/${typeId}`).then((response) => {
                    if (response.status !== 200) {
                        return;
                    }

                    this.compiledMailTemplate = response.data.result;
                    this.sendInvoiceModalActive = true;
                });

            },

            openSendPaymentReminder2Modal() {
                const typeId = this.$route.params.id;

                ApiService.get(`mail-templates/invoice_payment_reminder2/order/${typeId}`).then((response) => {
                    if (response.status !== 200) {
                        return;
                    }

                    this.compiledMailTemplate = response.data.result;
                    this.sendInvoiceModalActive = true;
                });

            },
            fetchOrderHistory() {
                const id = this.$route.params.id;

                ApiService.get(`order_history/${id}`, {
                    params: this.orderHistoryRequestParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then(response => {
                    if (response.status !== 200) {
                        return;
                    }

                    this.order_history = response.data.result.result;
                });
            },
            onPayDatePromptClose() {
                this.activePayedPrompt = false;


                ApiService.get(`orders/${this.$route.params.id}`).then(response => {
                    if(response.status === 200) {
                        this.order = response.data.result;
                    }

                    this.fetchOrderHistory();
                });
            },
            onClickDeleteOrder() {
                this.deleteOrderPrompt = true;
            },
            onClickCreateCreditNote() {
                this.createCreditNote = true;
            },
            onClickAddBankTransfer() {

                if (!this.order.client.bank_bic || !this.order.client.bank_iban || this.order.client.bank_bic === '' || this.order.client.bank_iban === '' ){
                    this.$vs.notify({
                        title: 'Fehler',
                        text: 'Der Empfänger-Kunde hat kein Bankverbindung hinterlegt',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                    return
                }

                this.$vs.loading();

                let payload = {};

                payload.internal_notice = 'Created from Credit-Note detail page';
                payload.client_id = this.order.client_id;
                payload.order_id = this.order.id;
                payload.workspace_id = this.workspace.id;
                payload.bic = this.order.client.bank_bic;
                payload.iban = this.order.client.bank_iban;
                payload.recipient_name = this.order.client.bank_account_owner ? this.order.client.bank_account_owner : this.order.client.company_name;
                payload.reference_text = 'Credit Note No.'+ this.order.invoice_number + ' - date ' +  moment(this.order.order_date).format('DD.MM.YYYY');
                payload.sum = PriceHelper.parseCommaFloat(this.order.sum);


                ApiService.post('bank-transaction-transfer', payload).then((response) => {
                    if (response.data.status == "success") {
                        this.$vs.loading.close();
                        this.activePayedPrompt=true
                        this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'Die Überweisung wurde erfolgreich erstellt.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        });
                        this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'Du solltest jetzt gleich die Gutschrift als Bezahlt markieren, wann denkst du wird die Gutschrift angewiesen?',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        });
                    }
                }).catch((error) => {
                    console.log(error)
                        this.$vs.notify({
                            title: 'Fehler',
                            text: 'Die Überweisung konnten nicht erstellt werden.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        });

                        this.$vs.loading.close()
                    }
                )
            },
            onDeleteOrder() {
                this.$vs.loading();

                ApiService.delete(`orders/${this.$route.params.id}`).then(response => {
                    if(response.status === 200) {
                        this.$vs.loading.close();
                        this.$router.push('/invoices');
                    }
                });
            },
            onSaveOrder() {
                const id = this.$route.params.id;
                const postData = {
                    categories: this.order.categories,
                    tags: this.order.tags,
                    notice: this.order.notice
                };

                this.$vs.loading();

                ApiService.put(`orders/${id}`, postData).then(response => {
                    if(response.status === 200) {
                        this.$vs.loading.close();

                        this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'Die Änderung wurde erfolgreich gespeichert',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        });
                    }
                }).catch(response => {
                    this.$vs.loading.close();

                    this.$vs.notify({
                        title: 'Fehler',
                        text: response.message,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                });
            },

            sendInvoiceMail({emails, content, attachments, from, cc, bcc, subject, personal}) {
                let formData = new FormData();

                formData.append('emails', emails);
                formData.append('content', content);
                formData.append('mail_template_id', this.compiledMailTemplate.id || this.order.type.mail_template_id);
                formData.append('subject', subject);
                formData.append('from', from);
                formData.append('cc', cc);
                formData.append('bcc', bcc);
                formData.append('personal', personal);

                if(attachments) {
                    for (var i = 0; i < attachments.length; i++) {
                        formData.append('additional_attachments[' + i + ']', attachments[i]);
                    }
                }

                this.$vs.loading();

                ApiService.post('orders/mail/' + this.order.id, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((response) => {
                    this.$vs.loading.close();

                    this.$vs.notify({
                        title: 'Erfolgreich',
                        text: 'Die Email wurde erfolgreich versendet',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    });

                    this.sendInvoiceModalActive = false;

                    this.fetchOrderHistory();

                }).catch((error) => {
                    this.$vs.loading.close();

                    if (error.response.data.message) {
                        this.$vs.notify({
                            title: 'Fehler',
                            text: error.response.data.message,
                            time: 6000,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    } else {
                        this.$vs.notify({
                            title: 'Fehler',
                            text: error.message,
                            time: 6000,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }

                });
            },
        }
    }
</script>

<style scoped>
    .pdfobject-container { height: 700px; }

    .taskAllocationItem:nth-child(even) {
        background-color: #efefef;

    }

    .taskAllocationItem {
        padding: 10px;
        border-radius: 6px;
    }

</style>
