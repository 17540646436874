<template>
    <div class="suggestion-input">
        <vue-tags-input
                class="w-full"
                v-model="tag"
                :tags="tags"
                @tags-changed="onTagsChanged"
                :autocomplete-items="filteredItems"
                :validation="validation"
                placeholder="E-Mail hinzufügen"
        />
    </div>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';
    import ApiService from "../../api";

    export default {
        name: "EmailSuggestionInput",
        components: {
            VueTagsInput,
        },
        props: {
            value: {
                type: Array,
                default: () => {
                    return [];
                }
            }
        },
        data() {
            return {
                tag: '',
                tags: [],
                suggestionTags: [],
                validation: [{
                    classes: 'invalid-email',
                    disableAdd: true,
                    rule: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                }],
            };
        },
        computed: {
            filteredItems() {
                return this.suggestionTags.filter(i => {
                    return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
                }).splice(0, 5);
            },
        },
        created() {
            this.tags = this.value.map(item => {
                return {
                    text: item
                }
            }, []);
            this.fetchEmailTags();
        },
        methods: {
            fetchEmailTags() {
                ApiService.get('suggestions/emails').then(response => {
                    this.suggestionTags = response.data.result.map(item => {
                        return {
                            text: item
                        }
                    }, []);
                });
            },
            onTagsChanged(tags) {

                tags = tags.map(tag => {
                    return tag.text
                }, []);

                this.$emit('input', tags);
                this.$emit('changed', tags)
            }
        },
        watch: {
            value(val) {
                this.tags = val.map(item => {
                    return {
                        text: item
                    }
                }, []);
            }
        }
    }
</script>

<style scoped>

</style>