<template>
    <div class="history-item">
        <div class="history-item__type"><div class="mg-label" :class="labelClass">{{history.event_type}}</div></div>
        <div class="history-item__date">{{history.created_at | date}}</div>
        <div class="history-item__description">
            {{history.event_description}}
            <a  v-if="history.changes" @click="active = true" class="text-pseudo-link">Änderungen anzeigen</a>
        </div>
        <div class="history-item__user">Von: {{history.user.detail.first_name}} {{history.user.detail.last_name}}</div>

        <vs-popup classContent="event-log-changes-modal--popup scrollable" class="event-log-changes-modal--popup" :title="history.event_description" @close="onClose"
                  :active="visible" >
            <div class="event-log-changes-modal">
                <div v-for="(change, key) in history.changes" :key="key" class="mt-3">
                    <b>{{ key }}:</b>
                    <p>Vorher: <span v-html="change.vorher == null ? '<i class=\'text-success\'>[leer]</i>' : change.vorher"></span></p>
                    <p>Nachher: <span v-html="change.nachher == null ? '<i class=\'text-success\'>[leer]</i>' : change.nachher"></span></p>
                </div>
            </div>
        </vs-popup>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: "HistoryItem",
        props: {
            history: {
                type: Object,
            }
        },
        data() {
            return {
                active: false,
            }
        },
        computed: {
            labelClass() {
                if(this.history.event_type === 'create') {
                    return 'success'
                }

                if(this.history.event_type === 'update') {
                    return 'warning'
                }

                if(this.history.event_type === 'danger') {
                    return 'warning'
                }
            },
            visible() {
                return this.active;
            },
        },
        methods: {
            onClose() {
                this.active = false;
            },
        },
        filters: {
            date: function (value) {
                return 'am ' + moment(value).format('DD.MM.YYYY HH:mm') + 'Uhr';
            }
        }
    }
</script>

<style scoped>
.text-pseudo-link {
    cursor: pointer;
}
.text-pseudo-link:hover {
    text-decoration: underline;
}
</style>