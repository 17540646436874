<template>
    <vs-popup id="product-modal" :title="title" :active="visible" @close="onClose" class="send-invoice-modal">
        <div class="flex">
            <div class="w-1/2 pr-2">
                <vs-input label="Absender" v-model="emailFrom" class="w-full mb-2" readonly></vs-input>
            </div>
            <div class="w-1/2 pl-2">
                <label class="vs-input--label --label">Empfänger</label>
                <email-suggestion-input v-model="emailAddresses" autocomplete="off" class="w-full"></email-suggestion-input>
            </div>
        </div>

        <div class="flex">
            <div class="w-1/2 pr-2">
                <label class="vs-input--label">CC</label>
                <email-suggestion-input v-model="emailCC" class="w-full"></email-suggestion-input>
            </div>
            <div class="w-1/2 pl-2">
                <label class="vs-input--label">BCC</label>
                <email-suggestion-input v-model="emailBCC" class="w-full"></email-suggestion-input>
            </div>
        </div>

        <vs-input label="Betreff" v-model="emailSubject" class="w-full mb-4"></vs-input>

        <p class="text-sm mb-2">Nachricht</p>

        <vue-editor :editorToolbar="toolbar" v-model="mailTemplate" class="w-full mb-4"></vue-editor>

        <vs-checkbox v-model="personalEmail" class="mb-4">Als persönliche Mail mit Mitarbeiter Signatur versenden</vs-checkbox>

        <p class="text-sm mb-2">Zusätzliche Anhänge</p>

        <input type="file" @change="onChangeFileSelection" multiple class="rounded bg-white shadow p-2"/>

        <div class="flex justify-end mt-6" v-if="!sendOnly">
            <vs-button color="danger" type="border" class="mr-auto" @click="onClose">Abbrechen</vs-button>
            <vs-button color="success" type="border" @click="onClickSkip">Überspringen</vs-button>
            <vs-button color="success" class="ml-2" @click="onClickSend">{{sendLabel}}</vs-button>
        </div>

        <div class="flex justify-end mt-6" v-else>
            <vs-button color="danger" type="border" class="mr-auto" @click="onClose">Abbrechen</vs-button>
            <vs-button color="success" class="ml-2" @click="onClickSend">{{sendLabel}}</vs-button>
        </div>

    </vs-popup>
</template>

<script>
    import {codemirror} from 'vue-codemirror';
    import 'codemirror/mode/htmlmixed/htmlmixed.js'
    import 'codemirror/lib/codemirror.css'
    import 'codemirror/theme/paraiso-light.css'
    import 'codemirror/theme/monokai.css'

    import codeMirrorOptions from '../../views/administration/email-templates/components/data/codemirror-options';

    import { Quill } from "vue2-editor";
    import EmailSuggestionInput from "../search/EmailSuggestionInput";

    const Block = Quill.import('blots/block');
    Block.tagName = 'DIV';
    Quill.register(Block, true);

    export default {
        name: "SendInvoiceModal",
        components: {EmailSuggestionInput, codemirror},
        props: {
            active: {
                type: Boolean,
                default: false
            },
            emails: {
                type: String,
            },
            template: {
                type: Object,
            },
            sendOnly: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: 'Rechnung versenden'
            },
            sendLabel: {
                type: String,
                default: 'Rechnung senden'
            },
        },
        data() {
            return {
                codeMirrorOptions: codeMirrorOptions,
                emailAddresses: null,
                mailTemplate: '',
                cmInstance: null,
                additionalAttachments: [],
                emailFrom: null,
                emailCC: [],
                emailBCC: [],
                emailSubject: null,
                editorOptions: {
                    showGutter: true
                },
                personalEmail: true,
                toolbar: [
                    ['bold', 'italic', 'underline'],
                    [{'list': 'ordered'}, {'list': 'bullet'}],
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }, { 'font': [] }],
                    [{ 'color': [] }, { 'background': [] }],
                    [{ 'script': 'sub'}, { 'script': 'super' }, { 'align': [] }],
                    ['link'],
                ],
            }
        },
        computed: {
            visible() {
                return this.active;
            },
        },
        created() {
            this.emailAddresses = this.emails.split(';');

            if(this.template !== null) {
                this.mailTemplate = '';

                if(this.template.preset_bcc) {
                    this.emailBCC = this.template.preset_bcc.split(';');
                } else {
                    this.emailBCC = [];
                }

                if(this.template.preset_cc) {
                    this.emailCC = this.template.preset_cc.split(';');
                } else {
                    this.emailCC = [];
                }

                if(this.template.is_html) {
                    this.mailTemplate = this.template.html_content.replace(/\n|\r/g, "");
                } else {
                    this.mailTemplate = this.template.plain_content.replace(/\n|\r/g, "");
                }

                this.emailFrom = this.template.from_email;
                this.emailSubject = this.template.subject;
            }
        },
        methods: {
            editorInit (editor) {
                require('ace-builds/src-min-noconflict/ext-language_tools');
                require('ace-builds/src-min-noconflict/mode-php_laravel_blade');
                require('ace-builds/src-min-noconflict/theme-tomorrow_night_bright');
                editor.session.setMode("ace/mode/php");
                editor.setOptions(this.editorOptions);
            },
            onClose() {
                this.$emit('update:active', false)
            },
            onClickSend() {
                this.$emit('send', {
                    emails: this.emailAddresses.join(';'),
                    content: this.mailTemplate,
                    attachments: this.additionalAttachments,
                    from: this.emailFrom,
                    cc: this.emailCC.join(';'),
                    bcc: this.emailBCC.join(';'),
                    subject: this.emailSubject,
                    personal: (this.personalEmail ? 1 : 0)
                });
                //this.$emit('update:active', false)
            },
            onClickSkip() {
                this.$emit('skip');
                this.$emit('update:active', false)
            },
            onChangeFileSelection(e) {
                const files = e.target.files || e.dataTransfer.files;

                if (!files.length) {
                    return;
                }

                this.additionalAttachments = files;
            }
        },
        watch: {
            active(value) {
                if(value) {
                    this.emailAddresses = this.emails.split(';');
                }

                if(this.template !== null) {
                    this.mailTemplate = '';

                    if(this.template.is_html) {
                        this.mailTemplate = this.template.html_content.replace(/\n|\r/g, "");
                    } else {
                        this.mailTemplate = this.template.plain_content.replace(/\n|\r/g, "");
                    }

                    if(this.template.preset_bcc) {
                        this.emailBCC = this.template.preset_bcc.split(';');
                    } else {
                        this.emailBCC = [];
                    }

                    if(this.template.preset_cc) {
                        this.emailCC = this.template.preset_cc.split(';');
                    } else {
                        this.emailCC = [];
                    }

                    this.emailFrom = this.template.from_email;
                    this.emailSubject = this.template.subject;
                }
            }
        }
    }
</script>

<style lang="scss">
    .con-vs-popup.send-invoice-modal .vs-popup {
        width: auto;
        min-width: 80vw;
        max-width: calc(100vw - 100px);
    }

    .send-invoice-modal {
        .vs-popup--content {
            overflow: auto;
        }
        .quillWrapper {
            .ql-editor {
                height: 16rem;
                min-height: 16rem;
                color: #000;
            }
        }
    }
</style>