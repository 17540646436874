<template>
    <div>
        <div class="TaskAllocationAvailable" v-if="dataTyp === 'accounting'">
            <vs-switch color="success" v-model="dataId.commission_option" :disabled="dataId.commission_complete == 1 || dataId.commission_complete == true" @change="setCommisionOption()">
                <span slot="on">Verfügbar</span>
                <span slot="off">Nicht verfügbar</span>
            </vs-switch>
        </div>
        <div v-if="dataId.commission_option || dataTyp === 'order_item'">


            <div  class="TaskAllocationUserList" >
                <div v-if="dataId.commission_complete == true || dataId.commission_complete == 1" class="disableTaskAllocation"></div>
                <div  class="item " v-for="user in users">
                    <div class="name">
                        {{user.detail.first_name}} {{user.detail.last_name}}
                    </div>
                    <div class="activeTask">
                        <vs-switch color="success" :disabled="user.commision_data.status_id >= 2"  v-model="user.commision_item" @click="activateUser(user)"/>
                    </div>
                    <div class="percent" v-if="user.commision_item">
                        <vs-input label-placeholder="Performance" :disabled="user.commision_data.status_id >= 2"  icon-after="true" icon="%" size="small"  v-model="user.commision_data.percent_performance" @change="changeInputHandler('percent_performance',user)" />
                    </div>
                    <div class="hours" v-if="user.commision_item">
                        <vs-input label-placeholder="" size="small" :disabled="user.commision_data.status_id >= 2"  v-model="user.commision_data.amount" @change="changeInputHandler('amount',user)" />
                        <span>{{unitName(user.commision_data.volume_unit)}}</span>
                    </div>
                    <div class="commision_sum" v-if="user.commision_item">
                        <vs-input label-placeholder="Umsatz" disabled="disabled"  icon-after="true" icon="EUR" size="small"  v-model="user.commision_data.sales_sum" />
                    </div>
                    <div class="actions"  v-if="user.commision_item && user.commision_data.status_id != '5'">
                        <feather-icon icon="MessageCircleIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" @click="openUserPopup(user)"   />
                        <feather-icon icon="ArchiveIcon"  class="inline-block mr-2" :class="{ 'active':  user.commision_data.status_id == '1' }" svgClasses="w-4 h-4" @click="changeState(user, 1)" title="Status: Entwurf"   />
                        <feather-icon icon="ThumbsUpIcon"  class="inline-block mr-2" :class="{ 'active':  user.commision_data.status_id == '2' }" svgClasses="w-4 h-4" @click="changeState(user, 2)"  title="Status: Freigegeben von Mitarbeiter"    />
                        <feather-icon icon="PauseIcon"  class="inline-block mr-2" :class="{ 'active':  user.commision_data.status_id == '3' }" v-if="user.commision_data.status_id == '3' " svgClasses="w-4 h-4"   title="Status: In Klärung"    />
                        <feather-icon icon="LoaderIcon"  class="inline-block mr-2" :class="{ 'active':  user.commision_data.status_id == '4' }" v-if="edit_status_to_approved_commission_item == true"  @click="changeState(user, 4)" svgClasses="w-4 h-4"   title="Status: Freigegeben von Chef"    />
                        <feather-icon icon="LoaderIcon"  class="inline-block mr-2" :class="{ 'active':  user.commision_data.status_id == '4' }" v-if="user.commision_data.status_id == '4' && edit_status_to_approved_commission_item == false"  svgClasses="w-4 h-4"   title="Status: Freigegeben von Chef"    />
                        <feather-icon icon="AwardIcon"  class="inline-block mr-2" :class="{ 'active':  user.commision_data.status_id == '5' }" v-if="user.commision_data.status_id == '5'"  svgClasses="w-4 h-4"   title="Status: Freigegeben von Chef"    />

                    </div>
                    <div class="actions"  v-if="user.commision_item && user.commision_data.status_id == '5'">
                        <feather-icon icon="MessageCircleIcon"  class="inline-block mr-2" svgClasses="w-4 h-4" @click="openUserPopup(user)"   />
                       <span style="color:green;">Abgerechnet</span>
                    </div>

                </div>
            </div>
            <div class="TaskAllocationUserSum">

                <div class="name">
                    Übrig:
                </div>
                <div class="percent"  :class="commision_performance_left < 0 ? 'has-error':''">
                    {{commision_performance_left}} %
                </div>
                <div class="hours" :class="commision_hours_left < 0 ? 'has-error':''">
                    {{commision_hours_left}}
                </div>
                <div class="commision_sum" >
                    {{sales_sum_left}} €
                </div>
            </div>

            <div class="invalidData">
                <vs-alert :active="commision_performance_left < 0" color="danger" icon="new_releases" >
                    <span>Die Performance <b>übersteigt</b> die maximal zulässige 100%.</span>
                </vs-alert>
            </div>



            <vs-popup classContent="popup-example"  title="Weitere Informationen" :active.sync="noticePopup" v-if="currentNoticePopupUser">
                <vs-textarea label="Beschreibung der  Abrechnung" v-model="currentNoticePopupUser.commision_data.description" />
                <vs-textarea label="Interne Notiz für Vorgesetzten" v-model="currentNoticePopupUser.commision_data.notice" />
            </vs-popup>
     </div>

    </div>
</template>

<script>

    import ApiService from "../../api";
    import staticOptions from "../../mixins/static/options";
    import vSelect from 'vue-select'
    import PriceHelper from "../../mixins/helper/price.helper";


    export default {
        name: "TaskAllocation",
        components: { 'v-select': vSelect,},
        props: {
            dataTyp:{
                type: String,
            },
            dataId:{
                type: Object,
            }
        },
        data() {
            return {
                users: [],

                noticePopup: false,
                currentNoticePopupUser: false,
                edit_status_to_approved_commission_item: false,
            }
        },
        computed: {
            visible() {
                return this.active;
            },
            unitOptions() {
                return staticOptions.units;
            },

            commision_performance_left() {
                return this.calculatePerformanceLeft();
            },
            commision_hours_left() {
                var availableHours = this.dataId.amount;
                for (var i = 0; i < this.users.length; i++) {
                    if ( this.users[i].commision_item === true && PriceHelper.parseComma(this.users[i].commision_data.amount) >= 0 ) {
                        availableHours  = availableHours - PriceHelper.parseComma(this.users[i].commision_data.amount);
                    }
                }
                return availableHours.toFixed(2);
            },
            sales_sum_left() {
                var sumCost = 0;

                if(this.dataTyp === "order_item") {
                    sumCost = PriceHelper.parseCommaFloat(this.dataId.unit_price_netto) * this.dataId.amount;
                } else if(this.dataTyp === "accounting") {
                    sumCost = PriceHelper.parseCommaFloat(this.dataId.unit_price) * this.dataId.amount - (PriceHelper.parseCommaFloat(this.dataId.ek_price) * this.dataId.amount);
                }
                for (var i = 0; i < this.users.length; i++) {
                    if ( this.users[i].commision_item == true && this.users[i].commision_data.sales_sum >= 1 ) {
                        sumCost  = sumCost - this.users[i].commision_data.sales_sum;
                    }
                }
                return PriceHelper.floatPointToComma(sumCost);
            },
            commisionComplete() {
                if(this.dataId.commission_complete == true) {
                    return 1;
                } else {
                    return 0;
                };
            },
            commisionAvailable() {
                if(this.dataId.commission_option == true) {
                    return 1;
                } else {
                    return 0;
                };
            }

    },
        async created() {
            this.$vs.loading();
            this.checkIfUserHasBillingPermission()

            await this.fetchUser()

            this.$vs.loading.close()

        },
        methods: {
            checkIfUserHasBillingPermission() {
                ApiService.post(`auth/functionPermission`, {permissionKey: "edit_status_to_approved_commission_item"} )
                    .then(response => {
                        if (response.data.status == "success") {
                            this.edit_status_to_approved_commission_item = true;

                        } else {
                            this.edit_status_to_approved_commission_item = false;
                        }
                    });
            },
            calculatePerformanceLeft() {
                var percentage = 100;
                for (var i = 0; i < Object.keys(this.users).length; i++) {
                    if (this.users[i] != undefined && this.users[i].commision_item === true && this.users[i].commision_data.percent_performance >= 1 ) {
                        percentage  = percentage - this.users[i].commision_data.percent_performance;
                    }
                }
                return percentage;
            },
            fetchUser() {

                if(this.dataTyp === "order_item") {
                    return ApiService.get('task-allocation-user-list/order_item/' + this.dataId.id).then(response => {
                        this.users = response.data.result;
                    }).catch(response => {
                    })
                } else if(this.dataTyp === "accounting") {
                    return ApiService.get('task-allocation-user-list/accounting/' + this.dataId.id).then(response => {
                        this.users = response.data.result;
                    }).catch(response => {
                    })
                }

            },
            completeCommission() {
                for (var i = 0; i < this.users.length; i++) {
                    if ( this.users[i].commision_item == true && this.users[i].commision_data.status_id == 1 ) {
                       this.users[i].commision_data.status_id = 2;
                    }
                }
            },
            openUserPopup(user) {
                this.noticePopup = true;
                this.currentNoticePopupUser = user;
            },
            completeTaskAllocation() {
                let data = {
                    commission_complete: 1,
                    commission_option: this.commisionAvailable
                };

                ApiService.put(`accountings/${this.dataId.id}`, data).then(() => {
                    this.$vs.notify({
                        title: 'Erfolgreich',
                        text: 'Die Änderung wurde erfolgreich gespeichert.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    });

                }).catch((error) => {
                    this.$vs.notify({
                        title: 'Fehler',
                        text: error.message,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                });
            },
            activateUser(user) {
               // console.log(user.commision_item)
            },
            unitName(value) {
                let name = staticOptions.units.find((item) => item.value == value);

                if(name === null || name === undefined) {
                    return null;
                }

                return name.labelShort;
            },
            changeState(user,statusId) {
                //console.log(user)
                if(user.commision_data.status_id <= 3){
                    user.commision_data.status_id = statusId
                }

            },
            setCommisionOption() {

                for (var i = 0; i < this.users.length; i++) {
                    if ( this.users[i].commision_item == true ) {
                        this.dataId.commission_option = true;
                    }
                }
            },
            changeInputHandler(method,user) {

                if(method === "percent_performance") {
                    if(this.commision_performance_left < 0 ) {
                        user.commision_data.percent_performance = user.commision_data.percent_performance - Math.abs(this.commision_performance_left);
                        this.$vs.notify({
                            title: 'Fehler',
                            text: 'Nicht mehr als 100% möglich. Ich habe es angepasst ;-)',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'warning'
                        })
                    }
                    user.commision_data.amount = parseFloat(((user.commision_data.percent_performance / 100) * this.dataId.amount).toFixed(5))


                    if(this.dataTyp === "order_item") {
                        user.commision_data.sales_sum = PriceHelper.parseCommaFloat(this.dataId.unit_price_netto) * user.commision_data.amount - (PriceHelper.parseCommaFloat(this.dataId.ek_price) * user.commision_data.amount);
                    } else if(this.dataTyp === "accounting") {
                        user.commision_data.sales_sum = PriceHelper.parseCommaFloat(this.dataId.unit_price) * user.commision_data.amount - (PriceHelper.parseCommaFloat(this.dataId.ek_price) * user.commision_data.amount);
                    }


                    //user.commision_data.commision_sum = ((PriceHelper.parseCommaFloat(this.dataId.unit_price) * user.commision_data.amount) * user.employee_settings.provision) / 100;
                }

                if(method === "amount") {
                    if(this.commision_hours_left < 0 ) {
                        user.commision_data.amount = PriceHelper.parseComma(user.commision_data.amount) - Math.abs(this.commision_hours_left);
                        this.$vs.notify({
                            title: 'Fehler',
                            text: 'Nicht mehr als 100% möglich. Ich habe es angepasst ;-)',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'warning'
                        })
                    }

                    user.commision_data.percent_performance = ((PriceHelper.parseComma(user.commision_data.amount) / PriceHelper.parseComma(this.dataId.amount))*100).toFixed(5)

                    if(this.dataTyp === "order_item") {
                        user.commision_data.sales_sum = (PriceHelper.parseCommaFloat(this.dataId.unit_price_netto) * PriceHelper.parseComma(user.commision_data.amount)).toFixed(2);
                    } else if(this.dataTyp === "accounting") {
                        user.commision_data.sales_sum = (PriceHelper.parseCommaFloat(this.dataId.unit_price) * PriceHelper.parseComma(user.commision_data.amount) - (PriceHelper.parseCommaFloat(this.dataId.ek_price) * PriceHelper.parseComma(user.commision_data.amount))).toFixed(2);
                    }


                //    user.commision_data.commision_sum = (((PriceHelper.parseCommaFloat(this.dataId.unit_price) * PriceHelper.parseComma(user.commision_data.amount)) * user.employee_settings.provision) / 100).toFixed(2);
                }

            }

        },
        watch: {

        }
    }
</script>

<style lang="scss">
    .TaskAllocationUserList {

        .name {
            float: left;
            width: 25%;
        }

        .activeTask {
            float: left;
            width: 10%;
        }

        .percent {
            float: left;
            width: 20%;
            margin-top: -17px;
            position: relative;
            .vs-component.vs-input {
                width: 100px;
                margin-top: 15px;
                .vs-input--placeholder.small{
                    padding: 0px 5px!important;
                }
            }
        }
        .hours {
            float: left;
            width: 16%;
            padding-top: 3px;
            position: relative;
            .vs-con-input-label {
                float: left;
                width: 50%;
                margin-right: 2%;
            }
            span {
                float: left;
                width: 48%;
                padding-top: 3px;
                position: relative;
            }
        }
        .commision_sum {
            float: left;
            width: 16%;
            margin-top: -17px;
            position: relative;
            .vs-icon{
                font-family: "DIN OT", Helvetica, Arial, sans-serif;
                font-size: 12px;
            }

            .vs-con-input-label {
                width: 100%;
            }
        }
        .actions {
            padding-left: 10px;
            float: left;
            width: 12%;
            margin-top: 6px;
            position: relative;
        }

        .item {
            clear: both;
            height: 50px;
        }
    }
    .TaskAllocationAvailable {
        position: absolute;
        top: -20px;
        right: 37px;

    }
    .TaskAllocationUserSum {
        position: relative;
        display: block;
        width: 100%;
        clear: both;
        border-top: 2px solid #e2e2e2;
        padding-top: 15px;
        .name {
            float: left;
            width: 33%;
        }

        .percent {
            float: left;
            width: 15%;
            text-align: center;
        }
        .hours {
            float: left;
            width: 16%;
            text-align: center;


        }
        .commision_sum {
            float: left;
            width: 16%;
            text-align: right;


        }
    }

    .TaskAllocationFinished {
        clear: both;
        margin-top: 70px;
        position: relative;
        display: block;

        .vs-switch {
            margin-top: 12px;
        }
    }

    .has-error {
        color:red;
    }
    .invalidData {
        margin-top: 50px;
    }
    .actions .feather-icon {
        cursor: pointer;

        &:hover {
            color:#d3331e;
        }
    }
    .actions .active {
        color: #71c73f;
        font-size: 13px;
    }
    .disableTaskAllocation {
        width: 96%;
        height: 95%;
        background: #ffffff8c;
        position: absolute;
        z-index: 9999;
    }
</style>