<template>
    <div class="history">
        <div class="history-title">
            Bearbeitungsverlauf
            <div class="badge-success">{{histories.length}}</div>
            <div class="action-open">
                <feather-icon  v-if="opened" icon="ChevronUpIcon" svgClasses="position-down" @click="toggle()"/>
                <feather-icon  v-else icon="ChevronDownIcon" svgClasses="position-down" @click="toggle()"/>
            </div>

        </div>
        <div class="history-items" v-show="opened">
            <div class="p-4" v-show="!histories || histories.length === 0">
                <vs-alert color="#7795D1" :active="true" icon="info"  >
                    Keine Log Einträge vorhanden
                </vs-alert>
            </div>

            <template v-if="histories">
                <history-item v-for="history in histories" :history="history"></history-item>
            </template>
        </div>
    </div>
</template>

<script>
    import HistoryItem from "./HistoryItem";

    export default {
        name: "History",
        components: {HistoryItem},
        props: {
            histories: {
                type: Array,
            },
            defaultOpened: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                opened: true
            }
        },
        created() {
            this.opened = this.defaultOpened;
        },
        methods: {
            toggle() {
                this.opened = !this.opened;
            }
        }
    }
</script>


<style lang="scss" scoped>
div.badge-success {
    background: green;
    height: 22px;
    width: 22px;
    position: relative;
    display: inline-block;
    border-radius: 20px;
    text-align: center;
    color: #fff;
    padding-top: 2px;
    margin-left: 10px;
}
.action-open {
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 20px;
    margin-top: 14px;
    &:hover {
        cursor: pointer;
        color: #599e0e;
    }
    svg {
        width: 30px;
        height: 30px;
    }
}

</style>